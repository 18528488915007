@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.dropzone {
	h2 {
		margin-bottom: 0.5rem;
		font-size: 1.1rem;
		line-height: 1.6rem;
	}

	.buttons {
		button {
			color: #fff;
			background: #1c1b31;
			border: 1px solid #5553da;
			border-radius: 8px;
			width: 100%;
			transition: all 200ms ease-in;

			&:hover {
				background-color: #2e2c6a;
			}
		}
	}

	.file {
		display: flex;
		justify-content: space-between;
		padding: 4px 16px;
		background: #1c1b31;
		border: 1px solid #5553da;
		border-radius: 0.5rem;
		transition: all 200ms ease-in;

		.label_control {
			display: flex;
			flex-direction: column;
			overflow: hidden;
			font-size: 1rem;
			max-width: 90%;
			text-wrap: nowrap;

			@include media($phone_all...) {
				font-size: 0.8rem;
			}

			small {
				font-size: 11px;
				color: #fff;
				font-weight: 600;
			}
		}

		button {
			background-color: none;
			margin: auto 0;

			svg {
				font-size: 1rem;
			}
		}

		&:hover {
			background-color: #2e2c6a;
		}
	}

	.footer {
		display: flex;
		justify-content: space-between;
		margin-top: 8px;

		small {
			font-size: 0.8rem;
			font-style: italic;
		}

		a {
			color: #bcb2aa;
			font-size: 0.8rem;
			line-height: initial;
		}
	}

	&.error {
		.helperTextLeft,
		h2 {
			color: #d32f2f;
			font-weight: bold;
		}
	}
}
