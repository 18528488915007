.crop_avatar {
	.helperTextLeft,
	.helperTextRight {
		font-size: 0.8rem;
		letter-spacing: initial;
	}

	.controls {
		margin-bottom: 0px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		& > div {
			flex: 1;
			max-width: 100%;
		}
	}

	.zoom_range {
		margin: -1rem auto 0;
		display: flex;
		align-items: center;
		width: 250px;
	}

	.crop_container {
		display: flex;
		position: relative;
		height: 404px;
		margin-top: 20px;
		margin-bottom: 20px;

		.crooper_container {
			border-radius: 10px;

			img {
				height: 100%;
			}
		}

		.crooper_crop_area {
			border: 2px solid #fff;
			border-radius: 10px;
			color: rgba(0, 0, 0, 0.7);
		}
	}

	.error {
		color: #d32f2f;
	}
}
