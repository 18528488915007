@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.newsreel_item {
	max-width: 600px;
	background: rgba(30, 29, 71, 0.35);
	margin: 16px 0 16px 0;
	border-radius: 10px;
	position: relative;

	.more_button {
		background: #f00;
	}

	.project_poster {
		max-width: 50px;
		border-radius: 8px;
		border: 2px solid #fff;
		margin-right: -1.25rem;
	}

	.more_button {
		background: #f00;
	}

	.project_poster {
		max-width: 50px;
		border-radius: 8px;
		border: 2px solid #fff;
		margin-right: -1.25rem;
	}

	.heading {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		padding: 1.5rem 1.5rem 0 1.5rem;

		@include media($phone_all...) {
			padding: 1rem 0.5rem 0 0.5rem;
		}

		span {
			font-size: 1rem;
			align-self: flex-end;
			justify-self: end;
		}

		button {
			color: #fff;
			top: 15px;
			right: 15px;
			position: absolute;

			svg {
				font-size: 0.8rem;
			}
		}
	}

	.content {
		padding: 0 1.5rem 0;
		width: 100%;
		margin: 16px 0 0;
		line-height: 1.3rem;
		font-size: 0.9rem;
		font-weight: normal;
		color: #fff;
		@include media($phone_all...) {
			padding: 0 0.5rem 0;
		}

		.contentImages {
			margin-top: 1rem;
			width: 100%;
		}

		img {
			position: relative;
			z-index: 200;
			display: flex;
			max-width: 99%;
			margin: 1%;
			margin-left: 0;
			margin-top: 0;
			border-radius: 0;

			@include media($phone_all...) {
				width: 98%;
			}
		}
	}

	.footer {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 16px 0;

		span {
			display: flex;
			align-items: center;
		}

		.add_comment_btn {
			background: #3cbb5b;
			width: 35px;
			height: 35px;
			color: #fff;
			font-family: 'Titillium Web', sans-serif;
			border-radius: 50%;
			cursor: pointer;
			border: none;
			margin-left: auto;

			svg {
				width: 1rem;
			}

			&:hover {
				background: #083ea3;
			}
		}

		.reactions {
			button {
				background: #000;
				color: #fff;
				height: 40px;
				padding: 0 10px;
				min-width: auto;
				margin-left: 10px;

				&:hover {
					background: #083ea3;
				}

				img {
					height: 20px;
					margin-right: 4px;
				}

				div {
					font-size: 1rem;
				}
			}
		}
	}

	.add_comment {
		button {
			margin-top: 12px;
			margin-right: 15px;
		}
	}

	.comments {
		padding: 0;
		padding-left: 3rem;
		width: 100%;
		margin-top: 1.5rem;
	}

	.comments :last-child span {
		display: none;
	}
}
