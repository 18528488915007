@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.comment {
	position: relative;
	display: flex;
	flex-direction: column;
	background: transparent;
	width: 100%;
	max-width: 100%;
	margin: 1rem 0 0 0;
	padding: 0;
	border-radius: 5px;

	.commentLine {
		position: absolute;
		top: 2rem;
		left: -2.3rem;
		bottom: -1.6rem;
		width: 1px;
		background: #fff;
	}

	.commentArrow {
		position: absolute;
		top: 0.9rem;
		left: -2.3rem;
		width: 1px;
		background: #fff;

		svg {
			position: absolute;
			bottom: -0.5rem;
			font-size: 0.9rem;
		}
	}

	.heading {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: -8px;
		margin-left: -8px;
		margin-bottom: 0px;

		span {
			font-size: 1rem;
			align-self: flex-end;
			justify-self: end;
		}

		button {
			color: #fff;

			svg {
				font-size: 0.8rem;
			}
		}
	}

	.content {
		margin: 16px 0;
		line-height: 1.3rem;
		font-size: 0.9rem;
		font-weight: normal;
		font-family: 'Titillium Web', sans-serif;
		color: #fff;
	}

	.footer {
		display: flex;
		align-items: center;
		@include media($phone_all...) {
			justify-content: left;
		}
	}
}
