@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

#main_image {
	cursor: pointer;
}

#ReactSimpleImageViewer {
	.react-simple-image-viewer__close {
		top: 80px;
		z-index: 300;
	}

	span {
		opacity: 0.8;
	}

	img {
		max-height: 80%;
		max-width: 80%;

		@include media($phone_all...) {
			max-height: 100%;
			max-width: 100%;
		}
	}
}
