@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.my_crop {
	position: relative;

	h2 {
		margin-bottom: 0;
		font-size: 1.1rem;
		line-height: 1.6rem;
		font-weight: 600;
	}

	small {
		font-size: 0.8rem;
		letter-spacing: initial;

		@include media($phone_all...) {
			display: flex;
			margin-bottom: 10px;
		}
	}

	.mobile_preview {
		display: none;

		@include media($phone_all...) {
			display: flex;
			justify-content: center;
			overflow: hidden;
			border-radius: 15px;
		}

		img {
			@include media($phone_all...) {
				height: 100%;
				border-radius: 15px;
			}
		}
	}

	.controls {
		margin-bottom: 20px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		@include media($phone_all...) {
			display: block;
			position: relative;
			z-index: 2;
		}

		.zoom-range {
			margin-right: 2rem;
			display: flex;
			align-items: center;

			@include media($phone_all...) {
				margin-left: 0.3rem;
				margin-right: 0;
				display: none;
			}
		}

		& > div {
			flex: 0.5;
			max-width: 50%;

			@include media($phone_all...) {
				max-width: 100%;
			}
		}
	}

	.crop-container {
		display: flex;
		position: relative;
		z-index: 2;
		width: 100%;
		height: 495px;
		background: #121212;
		border-radius: 0.5rem;

		@include media($phone_all...) {
			position: fixed;
			visibility: hidden;
		}

		.reactEasyCrop_Container {
			border: 2px solid #fff;
			border-radius: 10px;
			color: rgba(0, 0, 0, 0.7);
		}

		.reactEasyCrop_CropArea {
			border: 0;
		}
	}

	.error {
		color: #d32f2f;
	}
}
