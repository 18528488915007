@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.reactions {
	display: flex;
	flex-direction: row;
	position: relative;

	.reactions_box {
		position: absolute;
		left: -5px;
		top: -5px;
		z-index: 100;
		width: 230px;
		background: #000;
		border-radius: 20px;
		padding: 10px 10px 15px;

		h4 {
			font-weight: 600;
			font-size: 1rem;
			margin: 3px 0 0 40px;
		}
	}

	button {
		color: #fff;
		position: relative;
		z-index: 101;

		svg {
			width: 1.5rem;
			opacity: 0.5;
		}

		&:hover {
			> svg {
				opacity: 1;
				color: #08f996;
			}
		}
	}

	ul {
		background: transparent;
		display: flex;
		flex-direction: row;
		margin-top: 0px;
		margin-left: 40px;

		li {
			margin-right: 15px;

			button {
				border-radius: 20px;
				padding: 3px 0px;

				img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
}

.reaction_btn {
	background: #000 !important;
	color: #fff !important;
	height: 30px !important;
	padding: 10px 15px !important;
	min-width: auto !important;
	margin-left: 5px !important;
	border-radius: 50px !important;

	@include media($phone_all...) {
		margin-left: 0 !important;
		padding: 10px 8px !important;
	}

	&.highlight {
		background: #083ea3 !important;
	}

	img {
		height: 15px;
		margin-right: 4px;
	}
}
