@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.blue {
	font-size: 0.9rem;
	background: #4e4bde !important;
	border-radius: 2.5rem !important;
	color: #fff !important;
	height: 38px !important;
	line-height: inherit !important;
	text-transform: none !important;
	cursor: pointer !important;

	&:hover {
		background: #2b2972 !important;
	}

	&:disabled {
		color: rgba(255, 255, 255, 0.25) !important;
	}
}

.outlined {
	font-size: 0.8rem !important;
	border-radius: 1.4rem !important;
	border: 0.1rem double transparent !important;
	background-image: linear-gradient(#152d53, #152d53),
		radial-gradient(circle at left top, #016eda, #d900c0) !important;
	background-origin: border-box !important;
	background-clip: padding-box, border-box !important;
	padding: 0.5rem 1.5rem !important;
	color: #fff !important;
}
.outlined_border {
	font-size: 0.8rem !important;
	border-radius: 8px !important;
	text-wrap: nowrap;
	border: 0.1rem double transparent !important;
	background-image: linear-gradient(#152d53, #152d53),
		radial-gradient(circle at left top, #016eda, #d900c0) !important;
	background-origin: border-box !important;
	background-clip: padding-box, border-box !important;
	padding: 0.5rem 1.5rem !important;
	color: #fff !important;
	height: 45px;
}

.buy_fan {
	@extend .outlined;
	border-radius: 0 !important;
	background: none;
	background-image: none !important;
	border: none !important;
	padding: 0.5rem 1rem !important;
	min-width: 40px !important;

	@include media($phone_all...) {
		padding: 0.5rem !important;
	}

	svg {
		width: 16px;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 5px;
		border: 0.15rem solid transparent;
		background: linear-gradient(45deg, #016eda, #d900c0) border-box;
		-webkit-mask: linear-gradient(#fff 0 0) padding-box,
			linear-gradient(#fff 0 0);
		-webkit-mask-composite: destination-out;
		mask-composite: exclude;
	}
}

.outlined_square {
	@extend .outlined;
	border-radius: 4px !important;
}

.outlined_square_reverse {
	@extend .outlined;
	border-radius: 4px !important;
	font-weight: 700;
	font-size: 16px !important;
	background-image: linear-gradient(#1a1a2c, #1a1a2c),
		radial-gradient(circle at left top, #d900c0, #016eda) !important;
}

.outlined_square_black {
	@extend .outlined_square;
	background-image: linear-gradient(#000, #000),
		radial-gradient(circle at left top, #016eda, #d900c0) !important;
	font-weight: 600;
	font-size: 16px !important;
	margin-top: 20px !important;
	float: right;
}

.purple {
	font-size: 0.9rem !important;
	font-weight: 600 !important;
	background: #63327e !important;
	border-radius: 1.4rem !important;
	padding: 0.25rem 1rem !important;
	color: #fff !important;
}

.social_login {
	background: #222 !important;
	font-size: 1rem !important;
	padding: 8px 16px !important;

	&:hover {
		background: #222;
	}

	span {
		img {
			height: 1.6rem;
		}
	}

	svg {
		color: #55bcec;
		float: left;
		margin-right: 0.5rem;
		font-size: 1.6rem !important;
	}
}

.blue_w_border {
	font-size: 0.9rem;
	background: #413fa8 !important;
	border-radius: 25px !important;
	color: #fff !important;
	padding: 0.5rem 2.5rem !important;

	&:disabled {
		color: #ffffff96 !important;
	}
}

.blue_wo_border {
	font-size: 0.9rem;
	font-weight: 600;
	background: #4e4bde !important;
	border-radius: 25px !important;
	color: #fff !important;
	padding: 0.5rem 2.5rem !important;

	&:disabled {
		color: #ffffff96 !important;
	}
}

.cancel_dark_blue {
	font-size: 0.9rem;
	font-weight: 600;
	background: #2e2c6a !important;
	border-radius: 25px !important;
	color: #fff !important;
	padding: 0.5rem 2.5rem !important;

	&:disabled {
		color: #ffffff96 !important;
	}
}
.animation_button {
	margin: 20px;
	background: linear-gradient(
		270deg,
		#4e4bde 33.75%,
		#bd02b2 167.82%,
		#5348dc 251.62%
	);

	animation-timing-function: ease-in-out;
	// animation-duration: 300ms;
	animation: glowing 30s linear infinite;
	// transition: opacity 0.3s ease-in-out;
	background-size: 200% 100%;
	background-position: 100% 0;
	transition: all 0.3s ease-in-out;
}
@keyframes glowing {
	0% {
		background-position: 100% 0;
	}
	50% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 100% 0%;
	}
}

.no_text_transform {
	text-transform: none !important;
}

/* Redesign 08-2024 */

.blue_solid {
	display: inline-flex !important;
	height: 36px !important;
	padding: 8px 16px !important;
	justify-content: center !important;
	align-items: center !important;
	gap: 8px;
	flex-shrink: 0;
	border-radius: var(--radius-rounded-md, 6px) !important;
	background: var(--blue-500, #2196f3) !important;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05) !important;
	color: var(--text-text-foreground, #09090b) !important;
	font-family: Inter !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 20px !important; /* 142.857% */
	margin-right: 0rem !important;
	margin-left: 0.75rem !important;
}
