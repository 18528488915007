.thumbnails {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.thumbnail {
		display: flex;
		width: 100px;
		height: 100px;
		position: relative;
		overflow: hidden;
		margin: 6px 0px;
		align-items: center;
		justify-content: center;
		border-radius: 0.2rem;
		background: #21202e;
		margin-right: 12px;
		border-radius: 5px;

		button {
			position: absolute;
			position: absolute;
			right: -3px;
			top: -3px;
			color: #ffffff59;
		}

		img {
			width: 100%;
		}
	}
}
