.form_control_ta {
	width: 100%;

	.top {
		display: flex;

		em {
			margin-left: auto;
			font-size: 14px;
		}
	}

	h3 {
		margin-bottom: 10px;
	}

	textarea {
		width: 100%;
		min-width: 50%;
		max-width: 100%;
		transition: all 0.2s ease-out;
		padding: 0.87rem 1rem;
		display: inline-block;
		font-family: 'Titillium Web', sans-serif;
		font-weight: light;
		line-height: 1.2rem;
		border-radius: 0.5rem;
		appearance: none;
		font-size: 1rem;
		color: #fff;
		resize: none;
	}

	&.error {
		h3,
		p {
			color: #d32f2f;
			font-weight: bold;
		}

		textarea {
			border: 1px solid #d32f2f;
		}
	}
}

.black {
	textarea {
		background: #2e2c6a;
		border: 0.05rem solid #5553da;
		color: #fff;
		margin-bottom: 1rem;

		&:focus-visible {
			outline: none;
		}
	}
}

.grey {
	textarea {
		background-color: #262626;
		border: none;

		&:focus-visible {
			outline: none;
		}

		&::selection {
			background-color: #53abc9;
			color: #fff;
		}
	}
}

.blue {
	textarea {
		background-color: #2e2c6a;
		border-radius: 8px;
		border: 1px solid #5553da;

		&:focus-visible {
			outline: none;
		}

		&::selection {
			background-color: #53abc9;
			color: #fff;
		}
	}
}
