@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.dialog {
	.dialog_paper {
		background: linear-gradient(180deg, #2e2bba 0%, #1e1d47 100%);
		border: 1px solid #2e2bba;
		border-radius: 1rem !important;
		box-shadow: 0px 51px 69px rgba(23, 18, 43, 0.585739);
		max-width: 600px !important;
		position: relative;
		min-width: 400px;

		@include media($phone_all...) {
			margin: 16px;
			min-width: 350px;
		}

		& > button {
			position: absolute;
			top: 10px;
			right: 6px;
			z-index: 101;

			& > svg {
				height: 25px;
			}
		}
	}

	.dao_metric {
		display: flex;
		align-items: center;

		&.completed {
			svg,
			span {
				font-weight: 600;
				color: #50e3c2;
			}
		}

		button {
			border-radius: 10px;
			background: #01acbd;
			margin-left: auto;
			padding: 4px;
		}
	}

	.avatar {
		position: relative;
		margin-right: 20px;
		height: 62px;
		width: 62px;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			height: 100%;
			border-radius: 50%;
		}

		.ring {
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.container {
		color: #fff;
		font-style: normal;
		padding: 15px 25px 25px !important;

		@include media($phone_all...) {
			padding: 15px 10px 25px !important;
		}

		h2 {
			font-weight: 400;
			font-size: 22px;
			line-height: 33px;
			margin-bottom: 16px;

			@include media($phone_all...) {
				line-height: 23px;
				margin-bottom: 10px;
			}
		}

		.dao_details {
			display: flex;
			align-items: flex-end;
			margin-top: 16px;

			p {
				font-weight: 600;
				font-size: 16px;
				line-height: 21px;
				margin-right: 3px;

				@include media($phone_all...) {
					margin-bottom: 0.75rem;
				}
			}

			& > div {
				flex: 1;
				border: 1px solid #fff;
				margin-bottom: 8px;
			}
		}

		.info {
			display: flex;
			align-items: center;

			@include media($phone_all...) {
				margin-bottom: 1rem;
			}

			.message {
				em {
					color: #08f996;
					font-style: normal;
				}

				strong {
					color: #01b6df;
				}
			}

			p {
				letter-spacing: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 19px;
				margin-left: 16px;
				margin-top: 0;
				margin-bottom: 0;

				@include media($phone_all...) {
					line-height: 20px;
				}

				span {
					&.green {
						color: #08f996;
					}

					&.aqua {
						color: #50e3c2;
					}
				}
			}
		}

		.center {
			justify-content: space-between;
			margin-top: 16px;

			@include media($phone_all...) {
				display: initial;
			}

			& > div {
				border-radius: 15px;
				padding: 10px 15px;
				width: 100%;

				@include media($phone_all...) {
					width: 100%;
				}

				p {
					letter-spacing: normal;
					margin-top: 4px;

					svg {
						font-size: 0.9rem;
					}
				}
			}

			.left {
				background-color: #1b1660;
				margin-bottom: 1rem;

				p {
					display: flex;

					& > span {
						margin-left: 5px;

						& > span {
							color: #fc5353;
						}
					}

					svg {
						font-size: 16px;
						margin-left: auto;
					}
				}
			}

			.right {
				background-color: #11113a;
				position: relative;
				padding-bottom: 18px;

				.green {
					color: #50e3c2;
				}

				img {
					position: absolute;
					width: 15px;
					top: 10px;
					right: 15px;
				}

				span {
					margin-right: 5px;

					img {
						width: 25px;
						position: relative;
						display: inline-block;
						top: 5px;
						right: initial;
					}
				}
			}
		}

		.buttons {
			display: flex;
			margin-top: 20px;
			flex-direction: column;

			@include media($phone_all...) {
				line-height: 1rem;
			}

			button,
			a {
				background: rgba(78, 75, 222, 0.25);
				border-radius: 10px;
				padding: 0.5rem 0;
				width: 100%;
				font-size: 14px;
				color: #fff;
				display: flex;
				justify-content: center;
				align-items: center;

				@include media($phone_all...) {
					line-height: 1rem;
				}

				svg {
					font-size: 16px;
					margin-left: 10px;

					@include media($phone_all...) {
						margin-right: 20px;
					}
				}
			}

			.upgrade_dao {
				margin-bottom: 15px;
				background-color: #01acbd;

				&:disabled {
					opacity: 0.6;
				}
			}
		}
	}
}
