.error_fallback {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	margin-top: 20%;

	button {
		background: transparent;
		color: #fff;
		border: 1px solid #fff;
		padding: 10px 16px;
		cursor: pointer;
	}
}
