@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.referral_tracking {
	display: flex;
	flex-direction: column;
	padding: 20px 30px 22px;
	background: #2d2b3d url('../../../assets/images/union.svg') no-repeat;
	border-radius: 12px;
	margin-bottom: 25px;
	margin-top: 20px;

	@include media($phone_all...) {
		padding: 10px;
	}

	.title {
		font-weight: 700;
		font-size: 16px;
		margin-bottom: 16px;
		letter-spacing: normal;
	}

	.stats {
		display: flex;
		justify-content: space-between;

		.joined {
			background: #01995f;
			border-radius: 10px;
			padding: 0px 40px;

			@include media($phone_all...) {
				text-align: center;
				padding: 0 10px;
			}
		}

		@include media($phone_all...) {
			text-align: center;
		}

		.invitesLeft {
			color: #1aeff3;
		}
	}
}
