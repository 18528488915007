.crop_avatar {
	.controls {
		margin-bottom: 20px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		& > div {
			flex: 1;
		}
	}

	.zoom_range {
		margin: -1rem auto 0;
		display: flex;
		align-items: center;
		width: 250px;
	}

	.crop_container {
		display: flex;
		position: relative;
		height: 200px;
		margin-bottom: 20px;

		.crooper_container {
			img {
				height: 100%;
			}
		}

		.crooper_crop_area {
			color: #121212 !important;
			border-color: #fff;
			border-radius: 50%;
		}
	}
}
