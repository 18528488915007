.message {
	text-align: left;
	padding: 12px 20px;
	background-color: #222;
	border-radius: 4px;
	max-width: 500px;
	min-width: 300px;
	position: relative;
	box-shadow: 1px 7px 14px -5px rgb(0 0 0 / 20%);
	display: flex;
	flex-direction: row;

	> img {
		margin-right: 15px;
		height: 66px;
	}
}

.content {
	display: flex;
	flex-direction: column;
}

.title {
	color: #53abc9;
	font-size: 1.2rem;
	font-weight: 600;
	margin-top: 0;
	margin-bottom: 8px;
}

.msg {
	font-size: 14px;
	margin-top: 0;
	margin-bottom: 0;
	color: #fff;
}

.notification_extended {
	display: flex;
	flex-direction: column;
	background: #121416;
	box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
	border-radius: 15px;
	position: relative;
	max-width: 300px;
	width: 100%;
	padding-bottom: 10px;

	.header_text {
		font-family: 'Titillium Web';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		text-align: center;
		color: #ffffff;
	}

	.content_text {
		font-family: 'Titillium Web';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		color: #ffffff;
	}

	.header {
		margin: 10px;
		position: relative;
		display: flex;
		width: 280px;
		height: 150px;
		background: #292375;
		box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
		border-radius: 15px;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;

		.adornment {
			width: 5px;
			height: 53px;
			background: #9664ff;
			border-radius: 0px 100px 100px 0px;
			position: absolute;
			left: 0;
			top: 54px;
		}

		h1 {
			@extend .header_text;
			line-height: 30px;
			font-size: 20px;
		}

		div {
			position: relative;
			display: flex;
			align-items: center;

			.avatar {
				border: 2px solid #fff;
			}

			.arrow {
				margin: 0 20px;
			}

			.icon_only {
				position: absolute;
				background: #0e7a46;
				border-radius: 50%;
				padding: 8px;
				font-size: 18px;
				top: 10px;
				left: 35px;
			}
		}

		p {
			@extend .header_text;
		}
	}

	.content {
		h3 {
			@extend .content_text;
			width: 100%;
			border-bottom: 1px solid #333333;
			padding-left: 16px;
			padding-bottom: 4px;
		}

		div {
			display: flex;
			justify-content: space-between;
			padding: 12px 15px;

			span {
				@extend .content_text;
				font-family: var(--font-inter), sans-serif;

				img {
					width: 10px;
					height: 10px;
					margin-right: 2px;
				}
			}

			&:last-child {
				border-bottom: 1px solid #333333;
			}
		}
	}

	.footer {
		div {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 12px 15px;

			span {
				@extend .content_text;
				font-family: var(--font-inter), sans-serif;

				img {
					width: 10px;
					height: 10px;
					margin-right: 4px;
				}
			}

			p {
				font-size: 15px;
				font-weight: 600;
				line-height: 1rem;
				font-size: 13px;
				letter-spacing: 1px;
			}

			button {
				background: #eeeeee;
				border-radius: 10px;
				color: #000;
				font-weight: 400;
				height: 40px;
				width: 100px;
			}
		}

		.subinfo {
			font-size: 12px;
			text-align: center;
			display: flex;
			line-height: 0.9rem;
			max-width: 320px;
			padding: 0px 15px 5px 15px;
			font-style: italic;
			font-weight: 600;
		}
	}
}
