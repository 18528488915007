@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.profile_dashboard {
	margin-left: 0;
	display: flex;

	h5 {
		margin-bottom: 10px;
		margin-top: 32px;
		font-style: normal;
		font-weight: 600;
		font-size: 30px;
		line-height: 24px;
		display: inline-block;
	}

	& > div {
		@include media($phone_all...) {
			width: 100vw;
		}
	}

	.social_container {
		display: flex;
		margin-bottom: 26px;

		& > div {
			display: flex;

			a {
				display: flex;
				align-items: center;
			}
		}

		.country {
			font-size: 18px;

			strong {
				margin-left: 3px;
			}
		}

		.socials {
			a {
				color: #fff;
				margin-right: 14px;

				svg {
					font-size: 16px;
				}
			}
		}
	}

	.shareStats {
		display: flex;
		flex-direction: column;
	}

	.sub_header {
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 12px;
	}

	.about {
		display: block;
		color: #ffffff;
		font-style: normal;
		font-weight: 400;
		font-size: 15px;
		line-height: 24px;

		img {
			max-width: 100%;
		}

		p {
			letter-spacing: normal;
		}
	}

	.recent_activity {
		background: rgba(0, 0, 0, 0.35);
		border-radius: 20px;
		padding: 0 24px 24px;

		h5 {
			margin-top: 23px;
			margin-bottom: 40px;
		}
	}

	.achievments {
		margin-bottom: 30px;

		img {
			height: 52px;
			width: auto;
		}
	}
}
