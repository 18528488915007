@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.footer {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 8px 0;
	padding: 0 1rem 1rem;

	@include media($phone_all...) {
		
	}

	span {
		display: flex;
		align-items: center;
	}

	.commentCta {
		margin-right: 30px;

		@include media($phone_all...) {
			margin-right: 15px;
		}

		.add_comment_btn {
			width: 40px;
			height: 40px;
			color: #fff;
			font-family: 'Titillium Web', sans-serif;
			cursor: pointer;
			border: none;
			min-width: auto;
			padding: 0;

			svg {
				width: 1.2rem;
				opacity: 0.5;
			}

			&:hover {
				> svg {
					opacity: 1;
					color: #64ccf3;
				}
			}
		}

		span {
			display: inline-flex;
			font-weight: 100;
			font-size: 1rem;
			margin-left: 0px;
		}

		&:hover {
			span {
				opacity: 1;
				color: #64ccf3;
			}
		}
	}

	.reactions {
		button {
			background: #000;
			color: #fff;
			height: 40px;
			padding: 0 10px;
			min-width: auto;
			margin-left: 10px;

			svg {
				width: 1.25rem;

				&:hover {
					color: #64ccf3;
				}
			}

			&:hover {
				background: #083ea3;
			}

			img {
				height: 20px;
				margin-right: 4px;
			}

			div {
				font-size: 1rem;
			}
		}
	}
}

.more_button {
	width: 100%;
	margin: 0.5rem 0;
}

.comments {
	background: rgba(0, 0, 0, 0.75);
	padding: 1.5rem;
	padding-top: 0.5rem;
	padding-left: 3.5rem;
	width: 100%;
	margin-top: -0.5rem;
	border-radius: 0 0 10px 10px;

	@include media($phone_all...) {
	}
}

.comments > :last-child {
	> :last-child {
		display: none;
	}
}
