.avatar {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	&.tier_up {
		box-shadow: 0px 0px 10px 0px #f768ca;
		border-radius: 50%;
	}

	img {
		height: 100%;
		border-radius: 50%;
	}

	& > div {
		border: 3px solid transparent;
		height: 44px;
		width: 44px;
		margin: 0;
	}

	.ring {
		position: absolute;
		top: 0;
		left: 0;
	}

	.levelUp {
		position: absolute;
		top: 0;
		right: 0;
		height: 16px !important;
		width: 16px !important;
	}
}
