@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.stat_item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 11px;

	&.light_blue {
		color: #1aeff3;
	}

	&.green_highlight {
		background: #01995f;
		border-radius: 10px;
		padding: 0px 40px;

		@include media($phone_all...) {
			text-align: center;
			padding: 0 10px;
		}
	}

	h2 {
		font-style: normal;
		font-weight: 700;
		font-size: 40px;
	}

	p {
		font-style: normal;
		font-weight: 400;
		font-size: 10px;
		text-transform: uppercase;
		letter-spacing: normal;
	}
}
