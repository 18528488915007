.html_editor {
	width: 100%;

	h3 {
		margin-bottom: 10px;
	}

	.MuiFormHelperText-root {
		margin-top: 24px;
		font-size: 0.7rem;
		line-height: 1.2rem;
		font-style: normal;
		letter-spacing: inherit;
	}

	.ql-toolbar {
		border: 0.1rem solid #5553da;
		background: #1c1b31;
		border-radius: 8px 8px 0 0;

		.ql-picker-label.ql-active,
		.ql-picker {
			color: #fff;

			.ql-stroke {
				stroke: #fff;
			}

			.ql-picker-options {
				border-color: #ccc;
				background: #262626;
				border: none;
				padding: 0;
			}

			.ql-picker-label:hover {
				color: #fff;

				.ql-stroke {
					stroke: #fff;
				}
			}
		}

		.ql-picker-item {
			padding: 8px;

			&:hover {
				background-color: #000;
				color: #fff;
			}
		}

		button {
			.ql-picker,
			.ql-stroke {
				stroke: #fff;
			}

			.ql-fill,
			.ql-stroke.ql-fill {
				fill: #fff;
			}

			&:hover {
				.ql-stroke {
					stroke: #fff;
				}

				.ql-fill,
				.ql-stroke.ql-fill {
					fill: #fff;
				}
			}
		}
	}

	.ql-container {
		border: 0.1rem solid #5553da;
		border-radius: 0 0 8px 8px;
		overflow: hidden;

		.ql-editor {
			min-height: 10rem;
			background-color: #2e2c6a;

			p {
				font-size: 0.9rem;
				letter-spacing: normal;
				line-height: 1.2rem;
				font-weight: 300;
				margin: 0.5rem 0;
			}
		}
	}

	&.error {
		h3 {
			color: #d32f2f;
			font-weight: bold;
		}

		.helper_text {
			color: #d32f2f;
			font-weight: bold;
			margin-top: 3px;
			font-size: 0.8rem;
			font-style: italic;
			line-height: 1.6rem;
			letter-spacing: 0.1rem;
		}
	}
}
