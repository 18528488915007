.activities {
	width: 100%;

	.footer {
		display: flex;
		align-items: center;
		justify-content: center;

		button {
			background: rgba(0, 0, 0, 0.5);
			border: 1px solid rgba(255, 255, 255, 0.5);
			border-radius: 20px;
			padding: 4px 26px;
			width: 122px;
		}
	}
}

.section {
	padding: 20px;
	background: rgba(0, 0, 0, 0.35);
	border-radius: 20px;
	padding: 30px;

	h3 {
		font-weight: 700;
		font-size: 16px;
		margin-top: 32px;
		margin-bottom: 12px;
		color: #ffffff;
	}
}
