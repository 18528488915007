@import 'Variable.scss';

.chip_select_box_container {
	width: 100%;

	h3 {
		margin-bottom: 10px;
	}

	.chip_select_box {
		width: 100%;

		& * {
			&:focus-visible {
				outline: none !important;
			}
		}

		> div {
			margin-top: 0px;
			height: auto;
			display: inline-flex;
			flex-wrap: wrap;
			width: 100%;
			border-radius: 0.5rem;
		}

		> div > label {
			color: $whiteColor;
			top: 0;

			&.Mui-focused {
				opacity: 0;
			}
		}

		.MuiChip-root {
			color: $whiteColor;
		}

		.MuiAutocomplete-input {
			color: $whiteColor;

			&::placeholder {
				color: #7b7b7b;
			}
		}

		::placeholder {
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			opacity: 1;
			/* Firefox */
		}

		span {
			&.MuiAutocomplete-tag {
				display: none;
			}
		}

		.MuiOutlinedInput-root {
			border-radius: 8px;
			padding-top: 4px !important;
			padding-bottom: 4px !important;
			padding-right: 60px !important;

			> input {
				border: none;
			}
		}

		.MuiAutocomplete-endAdornment {
			margin-top: -2px;

			button {
				padding: 0 0px 0 0;

				&:last-child {
					svg {
						font-size: 2rem;
					}
				}
			}
		}
	}
}

.chip_select_box.black {
	> div {
		background-color: $blackColor;
		color: #7b7b7b;
	}

	fieldset {
		border: 1px solid #746064;
	}

	> div > label {
		color: $whiteColor;
	}

	.MuiChip-root {
		background-color: #2d2c2c;
		color: $whiteColor;
	}

	.MuiAutocomplete-input {
		color: $whiteColor;
	}

	svg {
		color: $whiteColor !important;

		&:hover {
			color: $whiteColor;
		}
	}

	::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: $whiteColor;
		opacity: 1;
		/* Firefox */
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: $whiteColor;
	}

	::-ms-input-placeholder {
		/* Microsoft Edge */
		color: $whiteColor;
	}
}

.chip_select_box.blue {
	> div {
		background-color: #2e2c6a;
		border-radius: 8px;
		border: 1px solid #5553da;
	}

	.MuiChip-root {
		background-color: #1c1b31;
		color: $whiteColor;
	}

	svg {
		color: $whiteColor !important;

		&:hover {
			color: $whiteColor;
		}
	}
}

.chip_select_box.grey {
	> div {
		background-color: #262626;
		color: #7b7b7b;
	}

	> div > label {
		color: $whiteColor;
	}

	.MuiChip-root {
		background-color: #111;
		color: $whiteColor;
	}

	.MuiAutocomplete-input {
		color: $whiteColor;
	}

	svg {
		color: #7b7b7b !important;

		&:hover {
			color: #7b7b7b;
		}
	}

	::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: $whiteColor;
		opacity: 1;
		/* Firefox */
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: $whiteColor;
	}

	::-ms-input-placeholder {
		/* Microsoft Edge */
		color: $whiteColor;
	}
}
