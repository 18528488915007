@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.dao_tiers {
	display: flex;
	background: #1e1d47;
	border-radius: 15px;
	padding: 0;
	margin-top: 35px;
	position: relative;

	@include media($phone_all...) {
		flex-direction: column;
	}

	.left {
		padding: 40px;

		@include media($phone_all...) {
			padding: 10px;
			display: flex;
		}

		svg {
			color: #01b6df;
			font-size: 16px;
			margin-left: 3px;
			top: -0.1rem;
			position: relative;
			cursor: pointer;
			opacity: 0.5;
			transition: all 200ms ease-in;

			&:hover {
				color: #fff;
				opacity: 1;
			}
		}

		p {
			font-weight: 600;
			font-size: 24px;
			line-height: 1.7rem;

			@include media($phone_all...) {
				font-size: 16px;
				margin-right: 4px;
			}
		}
	}

	.right {
		display: flex;
		margin-right: 65px;
		width: 100%;
		position: relative;

		.tiers_container {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
		}

		.progress_container {
			width: 100%;
			height: 100%;
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;

			.progress_base {
				width: calc(100% - 70px);
				margin-left: 2%;
				height: 5px;
				margin-top: -28px;
				background-color: #000;

				@include media($phone_all...) {
					margin-top: -20px;
					width: calc(100% - 60px);
				}

				.progress {
					height: 5px;
					background-color: aqua;

					.point {
						margin-left: 100%;
						width: 16px;
						height: 16px;
						border-radius: 50%;
						background-color: aqua;
						transform: translate(-8px, -6px);
					}
				}
			}
		}
	}
}