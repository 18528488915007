@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.activity_item {
	display: flex;
	margin-bottom: 30px;

	&>svg {
		width: 20px;
		margin-right: 20px;
		color: #08f996;
		margin-top: 4px;
	}

	.poster {
		float: right;
		width: 103px;
		height: 152px;
		border: 1px solid #ffffff;
		border-radius: 11px;

		@include media($phone_all...) {
			display: none;
		}
	}

	.activity_type {
		width: 36px;
		height: 36px;
	}

	.follow_text {
		display: flex;
		flex-direction: column;
		margin-right: 18px;
		width: 100%;

		@include media($phone_all...) {
			width: 100%;
		}

		h6 {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 27px;
			text-transform: capitalize;
			color: #e9e9e9;
			margin: 0;

			a {
				font-weight: 700;
				color: #5bcdec;
			}

			svg {
				width: 18px;
				height: 16px;
				margin-right: 6px;
			}
		}

		p {
			font-style: normal;
			font-weight: 400;
			line-height: 1.3rem;
			font-size: 14px;
			letter-spacing: normal;

			@include media($phone_all...) {
				max-width: 80vw;
			}

			&.pubdate {
				margin-top: 4px;
				margin-bottom: 7px;
			}

			&.logline {
				font-size: 15px;
				color: #b6b6b6;
				margin: 0;
				display: -webkit-box;
				-webkit-line-clamp: 4;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
		}
	}
}