@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.activity_item {
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
	position: relative;

	&.user_review {
		border-radius: 10px !important;
		border: 3px double transparent !important;
		background-image: linear-gradient(#121212, #121212),
			radial-gradient(circle at left top, #01bf52 13.13%, #01acbd 90.45%) !important;
		background-origin: border-box !important;
		background-clip: padding-box, border-box !important;
	}

	padding: 0.5rem 1.5rem !important;

	.header {
		display: flex;
		align-items: center;
		margin-bottom: 5px;

		svg {
			font-size: 20px;
			color: #08f996;
			margin-right: 18px;
		}

		p {
			font-weight: 400;
			font-size: 16px;
			line-height: 27px;
			color: #e9e9e9;

			span {
				font-weight: 700;
				color: #fff;
			}
		}

		.date {
			margin-left: auto;
			font-size: 14px;
			line-height: 24px;
		}
	}

	&>p {

		@include media($phone_all...) {
			max-width: 80vw;
		}

		img {
			position: relative;
			top: 0.4rem;
			width: 20px;
			height: 20px;
			margin: 0 2px;
		}
	}

	&>p,
	.rating {
		margin-left: 38px;
		margin-bottom: 5px;
		font-size: 13px;
	}

	.description {
		span {
			font-weight: 600;
		}
	}
}