@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.dialog {
	.dialog_container {
		background: rgba(0, 0, 0, 0.6);

		.dialog_paper {
			width: 100%;
			max-width: 352px;
			position: relative;
			background: linear-gradient(180deg,
					rgba(45, 42, 180, 0.5) 0%,
					rgba(20, 18, 78, 0.85) 50%);
			border: 1px solid #2e2bba;
			border-radius: 30px;
			color: #fff;
			padding: 32px 25px 20px;
			backdrop-filter: blur(5px);

			@include media($phone_all...) {
				max-width: 400px;
				margin: 0.5rem;
				padding: 15px;
			}

			.close_btn {
				position: absolute;
				right: 12px;
				top: 12px;
				z-index: 100;
			}

			input {
				background: #2e2c6a;
				border: 1px solid #5553da;
				border-radius: 8px;
			}

			.dialog_content {
				padding: 0;

				.subTitle {
					font-size: 10px;
				}

				@include media($phone_all...) {
					overflow: initial;
				}
			}
		}
	}
}

.confirm_dialog {
	.dialog_paper {
		background-color: #222 !important;
		border-radius: 6px !important;

		.close_btn {
			display: none;
		}
	}
}

.score_detail {
	.dialog_paper {
		max-width: 450px !important;

		.close_btn {
			svg {
				font-size: 20px;
			}
		}

		.dialog_content {
			overflow: hidden;
		}
	}
}

.join {
	.dialog_paper {
		.dialog_content {
			overflow: hidden;
		}
	}
}

.magicCreate {
	.dialog_paper {
		max-width: 384px !important;

		.dialog_content {
			overflow: hidden;
		}
	}
}

.add_stake_dialog {
	.dialog_paper {
		max-width: 688px !important;
		padding: 16px !important;
		border-radius: 8px !important;

		.dialog_content {
			overflow: hidden;
		}

		.close_btn {
			svg {
				font-size: 16px;
			}
		}
	}
}

.purple_background {
	background: linear-gradient(241.25deg,
			rgba(41, 39, 85, 0.35) 4.4%,
			rgba(41, 39, 84, 0.78) 61.77%,
			rgba(27, 24, 66, 0.35) 119.94%);
	box-shadow: 0px 51px 69px rgba(23, 18, 43, 0.585739);
}

.purple_dialog {
	.dialog_paper {
		@extend .purple_background;
		max-width: 688px !important;
		border-radius: 8px !important;
		backdrop-filter: blur(11.5px);
		border-radius: 10px;

		.dialog_content {
			overflow: hidden;
		}

		.close_btn {
			svg {
				font-size: 16px;
			}
		}
	}
}

.trailer {
	.dialog_paper {
		width: 90% !important;
		height: 90% !important;
		max-width: 100% !important;
		background-color: transparent !important;
		box-shadow: none;

		iframe {
			background: #000;
		}

		.dialog_content {
			overflow: hidden;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.stage_detail {
	.dialog_paper {
		width: 560px !important;
		max-width: 560px !important;

		.close_btn {
			color: #fff !important;

			* {
				color: #fff !important;
			}
		}
	}
}

.edit_tweet {
	.dialog_paper {
		background-color: transparent !important;
		box-shadow: none;

		h2 {
			font-size: 20px;
			font-weight: 600;
			margin-bottom: 20px;
		}

		.dialog_content {
			overflow: hidden;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.detect_dialogue {
	.dialog_paper {
		max-width: 550px !important;
	}

	.close_btn {
		display: none;
	}
}

.proposalView {
	.dialog_paper {
		padding: 0 !important;
		max-width: 504px !important;

		.close_btn {
			z-index: 100;
			background: #fff;

			svg {
				color: #000;
			}
		}
	}
}

.whitelist_transfer {
	.dialog_paper {
		max-width: 512px !important;
		border-radius: 30px !important;
		background: #2e2bb7 !important;
	}
}

.email_verification {
	.dialog_paper {
		max-width: 410px !important;
		padding: 32px 24px;
		border-radius: 32px;
		border: 1px solid #2e2bba;
		background: linear-gradient(180deg, #2d2ab4 0%, #14124e 100%) !important;
		backdrop-filter: blur(5px);
	}
}

.on_ramp {
	.dialog_paper {
		background: #141416 !important;
		max-width: 450px !important;
		border: none !important;
	}
}

.contest_joined_dialog {
	.dialog_paper {
		max-width: 380px !important;
	}
}

.superfan_perk_dialog {
	.dialog_paper {
		max-width: 571px !important;
		height: 581px !important;
		border-radius: 15px !important;
		border: 1px solid rgba(33, 150, 243, 0.50) !important;
		background: #0A1C2A !important;

		.close_btn {
			width: 14px;
			margin-right: 6px;
		}
	}
}