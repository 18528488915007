@import 'ThemeScss';

.autocomplete_box {
	width: 100%;
	margin-bottom: 15px !important;

	.root {
		background-color: #262626;
		border-radius: 8px;

		input {
			color: #fff;
		}

		.text_field {
			& > div {
				height: 45px;
				padding-top: 4px;

				&:hover {
					border: none;
					outline-width: 0;

					* {
						border: none;
					}
				}
			}
		}

		svg {
			color: #000;
		}
	}

	.label {
		color: #ffffff;
		margin-bottom: 10px;
	}

	&.error {
		& > div {
			border: 1px solid #d32f2f;
		}

		p,
		.label {
			color: #d32f2f !important;
		}
	}
}

.popper {
	& > div {
		background-color: #000 !important;
	}

	div {
		color: #fff;
	}

	li {
		color: #fff;

		&:hover {
			background-color: #1e1e1e;
			cursor: pointer;
		}
	}
}

.blue {
	svg {
		color: #fff;
	}

	& > div {
		background-color: #2e2c6a;
		border-radius: 8px;
		border: 1px solid #5553da;

		input {
			border: none !important;
		}

		div {
			background-color: #2e2c6a;
			border-radius: 8px;
			border: none;
		}
	}
}
