@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.loader {
	position: fixed;
	z-index: 998;
	border-radius: 0.5rem;
	background: rgba(0, 0, 0, 0.75);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	h1 {
		background: none;
		border-radius: 50%;
		position: absolute;
		color: $whiteColor;
		z-index: 999;
		font-size: 3rem;
		padding: 0.5rem;
	}

	#loader {
		display: block;
		position: relative;
		left: 50%;
		top: 50%;
		width: 150px;
		height: 150px;
		margin: -75px 0 0 -75px;
		border-radius: 50%;
		border: 3px solid transparent;
		border-top-color: $linkColorOther;
		animation: spin 1.7s linear infinite;
		z-index: 11;
	}
}

#loader:before {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
	right: 5px;
	bottom: 5px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: #d006ab;
	animation: spin-reverse 0.6s linear infinite;
}

#loader:after {
	content: '';
	position: absolute;
	top: 15px;
	left: 15px;
	right: 15px;
	bottom: 15px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: #f9c922;
	animation: spin 1s linear infinite;
}

.fadeIn {
	animation-name: fadeIn;
}

.animated_fast {
	animation-duration: 0.7s;
	animation-fill-mode: both;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@-webkit-keyframes spin-reverse {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(-360deg);
	}
}

@keyframes spin-reverse {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(-360deg);
	}
}

.loader_text {
	position: fixed;
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;

	& > div {
		margin-bottom: -10rem;
		text-align: center;

		p {
			letter-spacing: normal;

			@include media($phone_all...) {
				font-size: 18px;
				padding: 0 10px;
			}
		}
	}
}
