@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.projects {
	display: flex;
	margin-bottom: 30px;
	position: relative;

	.snap_item {
		display: flex;
		margin-right: 24px;

		.superfan_perk_item {
			cursor: pointer;

			img {
				width: 98px;
			}

			p {
				color: #FFF;
				text-align: center;
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: 20px;
				width: 98px;
			}
		}
	}

	button {
		font-size: 20px;
		height: 20px;
		background: transparent;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		&.prev {
			left: -20px;
			height: 100%;
			background: linear-gradient(90deg,
					#121212 53.9%,
					rgba(255, 255, 255, 0) 104.95%);
			z-index: 10;
			border-radius: 0;
			width: 50px;
			box-shadow: 10px 0px 12px 3px #0a0a0a;
			background-color: #0a0a0a;
		}

		&.next {
			right: -20px;
			height: 100%;
			background: linear-gradient(270deg,
					#121212 53.9%,
					rgba(255, 255, 255, 0) 104.95%);
			z-index: 10;
			border-radius: 0;
			width: 50px;
			box-shadow: -9px 0px 12px 3px #121212;
			background-color: #121212;

			@include media($phone_all...) {
				right: 0;
			}
		}
	}
}