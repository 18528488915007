@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';


.newsreel_container {
	margin-bottom: 80px;
	position: relative;

	.recent_activity_container {
		margin-top: 48px;
		padding: 20px;
		background: rgba(0, 0, 0, 0.35);
		border-radius: 20px;

		@include media($phone_all...) {
			margin-top: 18px;
		}

		h3 {
			font-size: 16px;
			font-weight: 700;
			margin-top: 32px;
			margin-bottom: 12px;
			color: #ffffff;

			@include media($phone_all...) {
				margin-top: 8px;
			}
		}
	}

	.message_box {
		display: flex;
		align-content: center;
		width: 600px;
		background: #1e1d47;
		box-shadow: 0px 60px 60px rgba(0, 0, 0, 0.25);
		border-radius: 20px;
		padding: 1rem;
		margin-top: 2rem;

		@include media($phone_all...) {
			width: 100%;
		}

		img {
			float: left;
			margin-left: 1rem;
			margin-right: 1em;

			@include media($phone_all...) {
				width: 70px;
				margin-left: 0.5rem;
				margin-right: 0.5em;
			}
		}

		h1 {
			font-size: 1rem;
			line-height: 1.1rem;
			margin-top: 1rem;

			@include media($phone_all...) {
				margin-top: 0;
			}
		}
	}

	.subtitle {
		font-weight: 700;
		font-size: 16px;
		margin-top: 32px;
		margin-bottom: 12px;
		color: #ffffff;
	}

	.badges_achievements {
		background: rgba(0, 0, 0, 0.35);
		border-radius: 20px;
		padding: 30px;
		margin-top: 3rem !important;

		@include media($phone_all...) {
			max-width: 100%;
			padding: 10px;
		}

		h3 {
			@extend .subtitle;

			&:first-child {
				margin-top: 5px;
			}
		}
	}

	.back_btn {
		width: calc(100% - 20px);
		background-color: rgba(0, 0, 0, 0.4) !important;
		margin-top: 32px !important;
		display: flex !important;
		justify-content: flex-start !important;
		border-radius: 11px !important;
		padding: 1rem !important;
		font-size: 1rem !important;
		font-weight: bold !important;
	}

}