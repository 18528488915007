@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.tier_item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	svg {
		color: #3dcf18;
		font-size: 16px;
		margin-bottom: 6px;
		visibility: hidden;
		opacity: 0;
	}

	img {
		position: relative;
		top: 0.5rem;
		width: 48px;
		height: 48px;
		border-radius: 50%;
		cursor: pointer;
		margin-bottom: 1rem;

		@include media($phone_all...) {
			width: 35px;
			height: 35px;
		}

		&:hover {
			box-shadow: 0 0 0.9rem 0.4rem #1aeff3;
			background: #1aeff3;
		}
	}

	&.confirmed {
		svg {
			visibility: visible;
			opacity: 1;
		}

		.bottom_icon {
			color: #fff;
		}
	}

	&.completed {
		img {
			top: 0;
			width: 65px;
			height: 65px;
			border: 2px solid #1aeff3;
			border-radius: 50%;
			background: linear-gradient(0deg, #1aeff3, #1aeff3);
			margin-bottom: 0;

			@include media($phone_all...) {
				width: 45px !important;
				height: 45px !important;
			}
		}
	}

	.bottom_icon {
		visibility: visible;
		opacity: 1;
		color: rgba(255, 255, 255, 0.15);
	}

	p {
		text-transform: uppercase;

		@include media($phone_all...) {
			font-size: 12px;
		}
	}
}

.tier_detail {
	background-color: transparent !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: none !important;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	svg {
		&.top_icon {
			background: #152d53;
		}
	}

	&.incompleted {
		.container {
			p {
				width: 100%;
				text-align: left;
				letter-spacing: normal;

				&.completed {
					svg {
						color: #6dcfc1;
					}

					span {
						color: #6dcfc1;
						font-weight: 600;
					}
				}
			}
		}
	}

	.container {
		border-radius: 11px;
		background-repeat: no-repeat;
		background-size: cover;
		color: #fff;
		padding: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 0;

		h2 {
			font-weight: 600;
			font-size: 14px;
			line-height: 18px;
			margin-top: 15px;
		}

		h3 {
			font-weight: 600;
			font-size: 12px;
			line-height: 15px;
			letter-spacing: normal;
			margin-bottom: 6px;
		}

		p {
			font-weight: 300;
			font-size: 11px;
			line-height: 15px;
			letter-spacing: normal;

			svg {
				font-size: 12px;
				margin-left: 2px;
			}
		}

		button {
			margin-top: 12px;
			background: #3b37c9;
			border-radius: 25px;
			color: #dfdfdf;
			margin-top: 30px;
		}

		div {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}
}
