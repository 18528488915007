.add_comment {
	margin: 0 1rem;
	padding-bottom: 1rem;

	textarea {
		margin-bottom: 0;
	}

	button {
		margin-top: 12px;
		margin-right: 15px;
	}
}
