@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.share_section {
	display: flex;

	& > div {
		display: flex;
		flex-direction: column;
		width: auto;
		align-items: center;
		margin-right: 30px;

		span {
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
		}

		button {
			border-radius: 50%;
			background-image: linear-gradient(#d900c0, #016eda);
		}
	}
}

.edit_tweet {
	display: flex;
	flex-direction: column;
	width: 100%;

	textarea {
		@include media($phone_all...) {
			margin-bottom: 0;
		}
	}

	.link {
		display: flex;
		align-items: flex-end;

		button {
			margin-left: 10px;
			margin-bottom: 6px;
		}

		.link_copied {
			font-size: 0.875;
			color: #fff;

			svg {
				font-size: 12px;
				margin-left: 5px;
			}
		}
	}

	.copy_link {
		display: none;
		font-size: 12px;
		margin-bottom: -10px;
		text-align: right;
		color: #fff;

		svg {
			color: #fff;
			font-size: 12px;
		}

		@include media($phone_all...) {
			display: block;
		}
	}

	.footer {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		margin-top: 20px;

		button {
			padding: 4px 24px;
		}

		.share_btn {
			background-color: #4e4bde;
			border-radius: 25px;
			margin-left: 10px;

			&:hover {
				background-color: #4e4bdea8;
			}
		}
	}

	.note {
		font-size: 12px;
		margin-top: 15px;
		line-height: 15px;

		@include media($phone_all...) {
			display: none;
		}
	}
}

.shareHeader {
	display: inline-block;
	padding: 0 5px;
	font-size: 11px !important;
	line-height: 15px !important;
}

.shareSection {
	display: flex;
	justify-content: flex-start;

	.emailShre,
	.twitterShre,
	.facebookShre,
	.linkedinShre,
	.customShre {
		width: 60px;
		text-align: center;
		margin-right: 10px;
		align-content: center;

		p {
			margin-top: 5px;
			font-size: 10px;
		}

		.walletBtn {
			margin-top: 15px;
			left: 50%;
			transform: translateX(-50%);
			display: flex;
			position: relative;
			box-sizing: border-box;
			width: 46px;
			height: 46px;
			background: rgba(255, 255, 255, 1);
			border-radius: 50%;
			overflow: hidden;
			vertical-align: middle;

			.backDrop {
				position: absolute;
				border: 2px solid #fff;
				border-radius: 50%;
				width: 46px;
				height: 46px;
				background: linear-gradient(
					137.48deg,
					rgba(255, 83, 188, 0.5) 15.13%,
					rgba(10, 252, 212, 0.5) 87.01%
				);
				backdrop-filter: blur(20.9709px);
			}

			svg {
				margin-top: 9px;
				color: #000;
			}
		}
	}
}
